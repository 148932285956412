import React from 'react';
import AppWrapper from "./components"; 
import './styles/app.css';

function App() {
  return (
    <AppWrapper/>
  );
}

export default App;
